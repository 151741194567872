import { useContext, useEffect, useRef, useState } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";

import {
  UserContext,
  BusinessContext,
  RoleContext,
} from "../contexts/AppContexts";

import SideNavigationMenu from "../components/SideNavigationMenu/SideNavigationMenu";
import { SignOutButton } from "../components/Buttons/SignOutButton";
import { LayoutNotificationItem } from "../components/LayoutNotificationItem";
import ChatBotBotPress from "../components/ChatBotBotPress";
import Footer from "../components/Footer";
import { ProfileButton } from "../components/Buttons/ProfileButton";

export default function Layout({ onImpersonate }) {
  const { user } = useContext(UserContext);
  const { business } = useContext(BusinessContext);
  const { role } = useContext(RoleContext);

  const navigate = useNavigate();
  const location = useLocation();

  const previousWindowWidth = useRef(window.innerWidth);

  const [isSideMenuCollapsed, setIsSideMenuCollapsed] = useState(false);
  const [isSideMenuEnabled, setIsSideMenuEnabled] = useState(
    window.innerWidth > 992
  );

  const [headerClassName, setHeaderClassName] = useState("");

  useEffect(() => {
    window.addEventListener("resize", () => updateSideMenuStatus());

    if (goToRegister()) {
      navigate("/register");
      return;
    }

    window.scrollTo(0, 0);

    // Clean up the interval when the component unmounts
    return () => {
      window.removeEventListener("resize", updateSideMenuStatus);
    };
  }, []);

  useEffect(() => {
    isSideMenuCollapsed
      ? document.body.classList.add("vertical-collpsed")
      : document.body.classList.remove("vertical-collpsed");
    isSideMenuEnabled
      ? document.body.classList.add("sidebar-enable")
      : document.body.classList.remove("sidebar-enable");
  }, [isSideMenuCollapsed, isSideMenuEnabled]);

  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsSideMenuCollapsed(false);
      setIsSideMenuEnabled(false);
    }
  }, [location]);

  useEffect(() => {
    switch (role?.type) {
      case "owner":
        setHeaderClassName("");
        break;
      case "support":
        setHeaderClassName("bg-info");
        break;
      case "accountant":
        setHeaderClassName("bg-success");
        break;
      default:
        setHeaderClassName("bg-danger");
        break;
    }
  }, [role]);

  const updateSideMenuStatus = function (toggle) {
    if (toggle) {
      if (window.innerWidth > 992) {
        setIsSideMenuCollapsed((value) => !value);
        setIsSideMenuEnabled(true);
      } else {
        setIsSideMenuCollapsed(false);
        setIsSideMenuEnabled((value) => !value);
      }
    } else {
      if (previousWindowWidth.current <= 992 && window.innerWidth > 992) {
        setIsSideMenuCollapsed(false);
        setIsSideMenuEnabled(true);
      } else if (
        previousWindowWidth.current > 992 &&
        window.innerWidth <= 992
      ) {
        setIsSideMenuCollapsed(false);
        setIsSideMenuEnabled(false);
      }
    }

    previousWindowWidth.current = window.innerWidth;
  };

  const goToRegister = () => {
    return business?.status !== 0;
  };

  const openRightBar = () => {
    const el = document.getElementById("main-body");
    el.classList.add("right-bar-enabled");
  };

  const closeRightBar = () => {
    const el = document.getElementById("main-body");
    el.classList.remove("right-bar-enabled");
  };

  const switchLang = (lang) => {
    console.log("🇪🇺 the user chose a new language:", lang);
  };

  return (
    !!user &&
    !!business &&
    !!role && (
      <>
        <div id="layout-wrapper" className={``}>
          <header id="page-topbar" className={headerClassName}>
            <div className="navbar-header">
              <div className="d-flex">
                <div className="navbar-brand-box">
                  <a href="index.html" className="logo logo-dark">
                    <span className="logo-sm">
                      <img
                        loading="lazy"
                        src="/assets/images/logo.svg"
                        alt="logo"
                        height="40"
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        loading="lazy"
                        src="/assets/images/logo-dark.png"
                        alt="logo"
                        height="40"
                      />
                    </span>
                  </a>
                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img
                        loading="lazy"
                        src="/assets/images/logo-light.svg"
                        alt="logo"
                        height="40"
                      />
                    </span>
                    <span className="logo-lg">
                      <img
                        loading="lazy"
                        src="/assets/images/logo-light.png"
                        alt="logo"
                        height="40"
                      />
                    </span>
                  </Link>
                </div>

                <button
                  type="button"
                  className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                  id="vertical-menu-btn"
                  onClick={() => updateSideMenuStatus(true)}
                >
                  <i className="fa fa-fw fa-bars"></i>
                </button>

                {/* <form className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Cerca..."
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </form> */}
              </div>
              {role?.type !== "owner" && (
                <div className="d-flex flex-column">
                  <div>Sei dentro a {business.businessName}</div>
                  <div>
                    <Link
                      className="btn btn-warning border border-1 border-dark rounded-3"
                      onClick={onImpersonate}
                    >
                      Torna al mio Holo
                    </Link>
                  </div>
                </div>
              )}
              <div className="d-flex">
                <div className="dropdown d-inline-block d-lg-none ms-2">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    id="page-header-search-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="mdi mdi-magnify"></i>
                  </button>
                  <div
                    className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                    aria-labelledby="page-header-search-dropdown"
                  >
                    <form className="p-3">
                      <div className="form-group m-0">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search ..."
                            aria-label="Recipient's username"
                          />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="submit">
                              <i className="mdi mdi-magnify"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>

                <div className="dropdown d-inline-block">
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      loading="lazy"
                      id="header-lang-img"
                      src="/assets/images/flags/italy.jpg"
                      alt="Header Language"
                      height="16"
                    />
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    {/* <a
                                        onClick={() => switchLang("en")}
                                        className="dropdown-item "
                                        data-lang="en"
                                    >
                                        <img
                                            loading="lazy"
                                            src="/assets/images/flags/us.jpg"
                                            alt="user-image"
                                            className="me-1"
                                            height="12"
                                        />{" "}
                                        <span className="align-middle">Inglese</span>
                                    </a> */}

                    <Link
                      className="dropdown-item "
                      data-lang="it"
                      onClick={() => switchLang("it")}
                    >
                      <img
                        loading="lazy"
                        src="/assets/images/flags/italy.jpg"
                        alt="italy flag"
                        className="me-1"
                        height="12"
                      />
                      <span className="align-middle">Italiano</span>
                    </Link>
                  </div>
                </div>

                {/* <div className="dropdown d-none d-lg-inline-block ms-1">
                <button
                  type="button"
                  className="btn header-item noti-icon waves-effect"
                  data-bs-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen"></i>
                </button>
              </div> */}

                <div className="dropdown d-inline-block">
                  <LayoutNotificationItem key={`layout-notification-item`} />
                </div>

                <div className="dropdown d-inline-block">
                  <button
                    type="button"
                    className="btn header-item waves-effect"
                    id="page-header-user-dropdown"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      loading="lazy"
                      className="rounded-circle header-profile-user"
                      src={
                        user.avatarPath ||
                        "/assets/images/avatars/wired-lineal-527-boy-young-men.gif"
                      }
                      alt={user.avatarPath || "Avatar"}
                    />
                    <span
                      className="d-none d-xl-inline-block ms-1"
                      key="t-henry"
                    >
                      {user.firstName}
                    </span>
                    <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end">
                    {/* <a className="dropdown-item d-block" href="settings.html">
                    <i className="bx bx-wrench font-size-16 align-middle me-1"></i>{" "}
                    <span key="t-settings">Impostazioni</span>
                  </a>
                  <a className="dropdown-item" href="userguide.html">
                    <i className="bx bx-help-circle font-size-16 align-middle me-1"></i>{" "}
                    <span key="t-my-guide">Guida utente</span>
                  </a>
                  <a className="dropdown-item" href="#">
                    <i className="bx bx-support font-size-16 align-middle me-1"></i>{" "}
                    <span key="t-my-support">Supporto</span>
                  </a> */}
                    <ProfileButton />
                    <div className="dropdown-divider" />
                    <SignOutButton />
                  </div>
                </div>

                {/* <div className="dropdown d-inline-block">
                <button
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                  onClick={() => openRightBar()}
                >
                  <i className="bx bx-cog bx-spin"></i>
                </button>
              </div> */}
              </div>
            </div>
          </header>

          <SideNavigationMenu
            onClickLink={() => updateSideMenuStatus(true)}
          ></SideNavigationMenu>

          <div className="main-content">
            <Outlet />
            <Footer />
          </div>

          <div id="chatbot-content">
            <ChatBotBotPress />
          </div>
        </div>

        <div className={`right-bar`}>
          <div data-simplebar className="h-100">
            <div className="rightbar-title d-flex align-items-center px-3 py-4">
              <h5 className="m-0 me-2">Settings</h5>

              <Link
                className="right-bar-toggle ms-auto"
                onClick={() => closeRightBar()}
              >
                <i className="mdi mdi-close noti-icon"></i>
              </Link>
            </div>

            <hr className="mt-0" />
            <h6 className="text-center mb-0">Choose Layouts</h6>

            <div className="p-4">
              <div className="mb-2">
                <img
                  loading="lazy"
                  src="/assets/images/layouts/layout-1.jpg"
                  className="img-thumbnail"
                  alt="layout images"
                />
              </div>

              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input theme-choice"
                  type="checkbox"
                  id="light-mode-switch"
                  // checked
                />
                <label className="form-check-label" htmlFor="light-mode-switch">
                  Light Mode
                </label>
              </div>

              <div className="mb-2">
                <img
                  loading="lazy"
                  src="/assets/images/layouts/layout-2.jpg"
                  className="img-thumbnail"
                  alt="layout images"
                />
              </div>
              <div className="form-check form-switch mb-3">
                <input
                  className="form-check-input theme-choice"
                  type="checkbox"
                  id="dark-mode-switch"
                />
                <label className="form-check-label" htmlFor="dark-mode-switch">
                  Dark Mode
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="rightbar-overlay"></div>
      </>
    )
  );
}

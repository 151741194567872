import { _BaseUtil } from "./_BaseUtil";

export const SubscriptionsUtil = {
  getStatusBadge: function (status) {
    switch (status) {
      case -1:
        return "bg-warning";
      case 0:
        return "bg-success";
      default:
        break;
    }
    return "";
  },

  getStatusDescription: function (status) {
    switch (status) {
      case -1:
        return "Disattivo";
      case 0:
        return "Attivo";
      default:
        break;
    }
    return "";
  },
};

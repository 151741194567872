import { useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

export const CardSimple = ({
  title,
  sub_title,
  icon,
  main_value,
  description,
  secondary_value,
  badge_className,
  trend_icon,
  link_to,
}) => {
  return (
    <>
      <div className="card">
        <div className="card-body card-std-height-xs">
          <div className="d-flex align-items-center mb-3">
            <div className="avatar-xs me-3">
              <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                <i className={icon}></i>
              </span>
            </div>
            <div>
              <h5 className="font-size-14 mb-0 text-capitalize-first-word">
                <Link to={link_to}>{title}</Link>
              </h5>
              <h6 className="font-size-12 mb-0 text-capitalize-first-word">
                {sub_title}
              </h6>
            </div>
          </div>
          <div className="text-muted mt-4">
            <h4>
              {main_value}{" "}
              <i className={`${trend_icon} ms-1 text-${badge_className}`}></i>
            </h4>
            <div className="d-flex">
              <span
                className={`badge badge-soft-${badge_className} font-size-12`}
              >
                {secondary_value}
              </span>
              <span className="ms-2 text-truncate text-capitalize-first-word">
                {description}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

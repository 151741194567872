export const CardSimpleSummary = ({ title, value, icon, titleClassName }) => {
  return (
    <>
      <div className="card mini-stats-wid">
        <div className="card-body">
          <div className="d-flex">
            <div className="flex-grow-1">
              <p className="text-muted fw-medium mb-2">{title}</p>
              <h4 className={`mb-0 ${titleClassName}`}>{value}</h4>
            </div>
            <div className="flex-shrink-0 align-self-center">
              <div className="mini-stat-icon avatar-sm rounded-circle bg-primary">
                <span className="avatar-title">
                  <i className={`${icon} font-size-24`}></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { Link } from "react-router-dom";

import { EstimatesApi_Cache } from "../../../api/decorators/cache/EstimatesApi_Cache";
import { MovementsApi_Cache } from "../../../api/decorators/cache/MovementsApi_Cache";
import { OrdersApi_Cache } from "../../../api/decorators/cache/OrdersApi_Cache";
import { InvoicesApi_Cache } from "../../../api/decorators/cache/InvoicesApi_Cache";
import { SubscriptionsApi_Cache } from "../../../api/decorators/cache/SubscriptionsApi_Cache";
import { TransactionsApi_Cache } from "../../../api/decorators/cache/TransactionsApi_Cache";

import BusinessCell, { BusinessCellHandler } from "../Cells/BusinessCell";
import ContactsDetailsCell, {
  ContactsDetailsCellHandler,
} from "../Cells/ContactDetailsCell";
import SalesCell, { SalesCellHandler } from "../Cells/SalesCell";
import DocumentsCell, { DocumentsCellHandler } from "../Cells/DocumentsCell";
import ExpensesCell, { ExpensesCellHandler } from "../Cells/ExpensesCell";
import { useContext } from "react";
import { BusinessContext } from "../../../contexts/AppContexts";

const BusinessesTableRowHandler = {
  map: async function (business, year) {
    if (!business) return;
    if (!year) return;

    const businessId = business.rowKey;

    const results = await Promise.all([
      EstimatesApi_Cache.get(businessId),
      OrdersApi_Cache.get(businessId),
      InvoicesApi_Cache.get(businessId),
      SubscriptionsApi_Cache.get(businessId),
      TransactionsApi_Cache.get(businessId),
      MovementsApi_Cache.get(businessId),
    ]);

    const estimates = results[0].filter(
      (x) => new Date(x.estimate.createdOn).getFullYear() === year
    );
    const orders = results[1].filter(
      (x) => new Date(x.order.deliveryDate).getFullYear() === year
    );
    const invoices = results[2].filter(
      (x) => new Date(x.invoice.createdOn).getFullYear() === year
    );
    const subscriptions = results[3].filter(
      (x) => new Date(x.createdOn).getFullYear() === year
    );
    const transactions = results[4];
    const movements = results[5].filter(
      (x) => new Date(x.madeOn).getFullYear() === year && x.amount < 0
    );

    let paidTransactions = transactions.filter(
      (x) => x.status === 3 && new Date(x.closedOn).getFullYear() === year
    );
    let toPayTransactions = transactions.filter(
      (x) =>
        [1, 2].includes(x.status) &&
        new Date(x.expirationDate).getFullYear() === year
    );

    // distinct
    const orderIds = [...new Set(orders.map((x) => x.order.rowKey))];
    let remainingTransactions = transactions.filter(
      (x) =>
        [1, 2].includes(x.status) &&
        !x.expirationDate &&
        orderIds.includes(x.orderId)
    );

    let salesPaidAmount = paidTransactions
      .map((x) => x.amountGross)
      .reduce((total, num) => Number(total) + Number(num), 0);
    let salesToPayAmount = [...toPayTransactions, ...remainingTransactions]
      .map((x) => x.amountGross)
      .reduce((total, num) => Number(total) + Number(num), 0);

    let record = {
      id: business.rowKey,
      businessCell: BusinessCellHandler.get(business),
      contactsCell: ContactsDetailsCellHandler.get(
        business.certifiedEmail,
        business.businessEmail,
        business.businessPhone
      ),
      salesCell: SalesCellHandler.get(salesPaidAmount, salesToPayAmount),
      documentsCell: DocumentsCellHandler.get(
        estimates,
        orders,
        invoices,
        subscriptions,
        paidTransactions
      ),
      expensesCell: ExpensesCellHandler.get(movements),
    };
    return record;
  },
};

const BusinessesTableRow = ({ key, row, onImpersonate }) => {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  return (
    <>
      <tr key={key}>
        <td>
          <Link
            to={`/business/${row.id}/profile`}
            className="text-primary fw-bold fs-4"
          >
            <i class="bx bx-right-arrow-alt"></i>
          </Link>
        </td>
        <td>
          <BusinessCell cell={row.businessCell} />
        </td>
        <td>
          <ContactsDetailsCell cell={row.contactsCell} />
        </td>
        <td colspan={3}>
          <SalesCell cell={row.salesCell} />
        </td>
        <td>
          <DocumentsCell cell={row.documentsCell} />
        </td>
        <td>
          <ExpensesCell cell={row.expensesCell} />
        </td>
        <td>
          <div className="dropdown">
            <Link
              className="dropdown-toggle card-drop"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="mdi mdi-dots-horizontal font-size-18"></i>
            </Link>
            <div className="dropdown-menu dropdown-menu-end">
              {/* {row.id !== businessId && ( */}
              <Link
                onClick={() => onImpersonate(row.id)}
                className="dropdown-item"
              >
                Entra
              </Link>
              {/* )} */}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default BusinessesTableRow;
export { BusinessesTableRowHandler };

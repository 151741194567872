import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { BusinessContext } from "../contexts/AppContexts";

import { OpenBankingApi_Cache } from "../api/decorators/cache/OpenBankingApi_Cache";
import { StripeApi_Cache } from "../api/decorators/cache/StripeApi_Cache";

import { BankAccountsAggregate } from "../components/BankAccountsAggregate";
import { Movements } from "../components/Movements";
import { BankAccountResume } from "../components/BankAccountResume";
import { OpenBankingCTA } from "../components/OpenBankingCTA";
import {
  CardSimpleCTA,
  button as CardSimpleCTAButton,
} from "../components/CardSimpleCTA";

export default function BankAccounts() {
  const { business } = useContext(BusinessContext);
  const businessId = business.rowKey;

  const [obAccounts, setOBAccounts] = useState([]);
  const [obTransactions, setOBTransactions] = useState([]);

  useEffect(() => {
    const fetchObAccounts = async () => {
      setOBAccounts(await OpenBankingApi_Cache.getAccounts(businessId));
    };
    fetchObAccounts();
  }, []);

  const loadOBTransactionsAsync = async () => {
    if (!obAccounts?.length) return;

    let accountPromises = obAccounts.map((a) => {
      return OpenBankingApi_Cache.getTransactionsAsync(businessId, a.uuid);
    });

    let promises = await Promise.all(accountPromises);

    let results = promises.map((p) => {
      return p;
    });

    results = results.flat(1);

    results = results.map((r, index) => {
      r.account = obAccounts.find((a) => a.uuid === r.account.uuid);
      return r;
    });

    setOBTransactions(results);
  };

  useEffect(() => {
    loadOBTransactionsAsync();
  }, [obAccounts]);

  const onConnectClick = async (type) => {
    switch (type) {
      case 1:
        connectOpenBanking();
        break;
      case 2:
        connectStripe();
        break;
      default:
        console.log(`Unmanaged type`, type);
        break;
    }
  };

  const connectOpenBanking = async () => {
    let returnUrl = `${window.location.origin}?reset=true`;

    let connectUrl = await OpenBankingApi_Cache.connectAsync(
      businessId,
      returnUrl
    );
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  const connectStripe = async () => {
    console.log("connectStripe");
    let connectUrl = await StripeApi_Cache.connectAsync(businessId);
    if (!connectUrl) {
      console.log("connectUrl empty");
      return;
    }
    window.location.href = connectUrl;
  };

  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">Conti</h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/bankAccounts">Conti</Link>
                    </li>
                    <li className="breadcrumb-item active">Tutti i Conti</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>

          {!obAccounts?.length && !business.stripeConnectId ? (
            <>
              <OpenBankingCTA showCta={false} />

              <CardSimpleCTA
                number={"!"}
                headerLink={`#`}
                title={`Scegli come vuoi esser pagato`}
                subtitle={`Stripe: il sistema più semplice di incasso. OpenBanking: il nuovo sistema per incassare con bonifico tramite un semplice link di pagamento`}
                buttons={[
                  CardSimpleCTAButton(
                    `Attiva Stripe`,
                    connectStripe,
                    `bx-link`
                  ),
                  CardSimpleCTAButton(
                    `Collega OpenBanking`,
                    connectOpenBanking,
                    `bx-link`
                  ),
                ]}
              />
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-xl-4">
                  <BankAccountsAggregate
                    accounts={obAccounts}
                    transactions={obTransactions}
                    onConnectClick={onConnectClick}
                  />
                </div>
                <div className="col-xl-8">
                  <BankAccountResume
                    accounts={obAccounts}
                    transactions={obTransactions}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <Movements useSearch={true} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

import { useContext, useEffect } from "react";

import { UserContext, BusinessContext } from "../contexts/AppContexts";

const ChatBotBotPress = () => {
  const { user } = useContext(UserContext);
  const { business } = useContext(BusinessContext);

  const scriptId = "botpress-webchat-script";

  const loadBotpressScript = () => {
    let script = document.getElementById(scriptId);
    if (script) return;

    script = document.createElement("script");
    script.id = scriptId;
    script.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.botpressWebChat.init({
        botId: "694df897-8d5b-4ad6-89f2-2589d05462da",
        hostUrl: "https://cdn.botpress.cloud/webchat/v1",
        messagingUrl: "https://messaging.botpress.cloud",
        clientId: "694df897-8d5b-4ad6-89f2-2589d05462da",
        composerPlaceholder: "Chat with Holo",
        botConversationDescription: "Lo specialista del regime forfettario",
        locale: "it",
        showTimestamp: true,
        className: "h-50 d-inline-block",
        botName: "Holo",
        lazySocket: true,
        themeName: "prism",
        avatarUrl:
          "https://getholo.finance/images/apple-touch-icon-152x152.png",
        website: "https://getholo.finance",
        stylesheet: `${process.env.REACT_APP_REDIRECT_URL}/assets/css/botpress.css`,
        frontendVersion: "v1",
        theme: "prism",
        themeColor: "#2563eb",
        useSessionStorage: true,
        userData: {
          utente: user,
          azienda: business,
          dashboard: {
            incassato_febbraio: 1387,
          },
        },
      });

      setTimeout(() => {
        // window.botpressWebChat.sendEvent({ type: "show" });
      }, 2000);

      setTimeout(() => {
        window.botpressWebChat.sendPayload({
          type: "card",
          title: `Salve ${user.firstName},`,
          subtitle: `sono il suo assistente personale di Holo, posso interrogare i suoi dati e aiutarla a monitorare l'andamento della sua attività ${business.businessName}`,
          image: "https://getholo.finance/images/apple-touch-icon-152x152.png",
          // actions: [
          //   {
          //     action: "Postback",
          //     title: "Cos'è Holo?",
          //     text: "Cos'è Holo?",
          //   },
          //   {
          //     action: "Postback",
          //     title: "Quanti contatti ho?",
          //     text: "Quanti contatti ho?",
          //   },
          //   {
          //     action: "Postback",
          //     title: "Quanti preventivi ho creato?",
          //     text: "Quanti preventivi ho creato?",
          //   },
          // ],
        });
      }, 5000);
    };
  };

  useEffect(() => {
    if (!user || !business) return;
    loadBotpressScript();
  }, []);

  return <div id="webchat" />;
};

export default ChatBotBotPress;

import clsx from "clsx";
import { useState } from "react";
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";

export default function SideNavigationMenu() {
  const [activeGroupItemId, setActiveGroupItemId] = useState(null);

  const groups = [
    {
      label: "I tuoi risultati",
      items: [
        {
          label: "Dashboard",
          id: "dashboard",
          icon: "bx-chalkboard",
          link: "/",
          items: [],
        },
      ],
    },
    {
      label: "Il tuo lavoro",
      items: [
        {
          label: "Contatti",
          id: "contacts",
          icon: "bxs-user-detail",
          link: "/contacts",
          items: [],
        },
        {
          label: "Vendite",
          id: "sales",
          icon: "bx-briefcase-alt-2",
          link: null,
          items: [
            {
              label: "Preventivi",
              id: "sales__estimates",
              link: "/estimates",
            },
            {
              label: "Ordini",
              id: "sales__orders",
              link: "/orders",
            },
            {
              label: "Sottoscrizioni",
              id: "sales__subscriptions",
              link: "/subscriptions",
            },
          ],
        },
        {
          label: "Contabilità",
          id: "accounting",
          icon: "bx-receipt",
          link: null,
          items: [
            {
              label: "Fatture",
              id: "accounting__invoices",
              link: "/invoices",
            },
            {
              label: "Pagamenti",
              id: "accounting__payments",
              link: "/payments",
            },
          ],
        },
        {
          label: "Listino",
          id: "price_list",
          icon: "bxs-file",
          link: null,
          items: [
            {
              label: "Servizi",
              id: "price_list__services",
              link: "/services",
            },
            {
              label: "Piani",
              id: "price_list__plans",
              link: "/plans",
            },
          ],
        },
      ],
    },
    {
      label: "I tuoi assets",
      items: [
        {
          label: "Tutti i conti",
          id: "bank_accounts",
          icon: "bxs-bank",
          link: "/bankAccounts",
        },
        // {
        //   label: "Fiat",
        //   id: "fiat",
        //   icon: "bx-euro",
        //   link: null,
        //   items: [
        //     {
        //       label: "Revolut",
        //       id: "fiat__revolut",
        //       link: "/revolut",
        //     },
        //     {
        //       label: "Stripe",
        //       id: "fiat__stripe",
        //       link: "/stripe",
        //     },
        //     {
        //       label: "Gateway di pagamento",
        //       id: "fiat__fiat_gate",
        //       link: "/fiat-gate",
        //     },
        //   ],
        // },
        // {
        //   label: "Crypto",
        //   id: "crypto",
        //   icon: "bx-bitcoin",
        //   link: null,
        //   items: [
        //     {
        //       label: "Wallets",
        //       id: "crypto__wallets",
        //       link: "/wallets",
        //     },
        //     {
        //       label: "Gateway di pagamento",
        //       id: "crypto__crypto_gate",
        //       link: "/crypto-gate",
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   label: "Business",
    //   items: [
    //     {
    //       label: "Elenco",
    //       id: "businesses",
    //       icon: "bx-user-circle",
    //       link: "/businesses",
    //     },
    //   ],
    // },
    {
      label: "Holo",
      items: [
        {
          label: "I tuoi Holo",
          id: "your_businesses",
          icon: "bxs-landscape",
          link: "/businesses",
          items: [],
        },
      ],
    },
  ];

  const renderGroupItem = function (item) {
    const isActiveGroupItem = item.id === activeGroupItemId;

    if (item.link) {
      return (
        <li key={item.id} className={clsx({ "mm-active": isActiveGroupItem })}>
          <Link
            to={item.link}
            className={({ isActive }) =>
              clsx("waves-effect", { "mm-active": isActive })
            }
            onClick={() => setActiveGroupItemId(null)}
            aria-expanded={isActiveGroupItem}
          >
            <i className={clsx("bx", item.icon)}></i>
            <span>{item.label}</span>
          </Link>
        </li>
      );
    }

    return (
      <li key={item.id} className={clsx({ "mm-active": isActiveGroupItem })}>
        <Link
          onClick={(e) => {
            e.preventDefault();
            setActiveGroupItemId(
              activeGroupItemId !== item.id ? item.id : null
            );
          }}
          className="has-arrow waves-effect"
          aria-expanded={isActiveGroupItem}
        >
          <i className={clsx("bx", item.icon)}></i>
          <span>{item.label}</span>
        </Link>

        {!!item.items?.length && (
          <ul
            className={clsx("sub-menu", "mm-collapse", {
              "mm-show": isActiveGroupItem,
            })}
          >
            {item.items.map((x) => {
              return (
                <li key={x.id}>
                  <Link
                    to={x.link}
                    className={({ isActive }) =>
                      clsx({ "mm-active": isActive })
                    }
                  >
                    {x.label}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className="vertical-menu mm-active">
      <SimpleBar className="h-100 mm-show">
        <div id="sidebar-menu" className="mm-active">
          <ul className="metismenu list-unstyled mm-show" id="side-menu">
            {groups.map((x, i) => {
              return (
                <div key={i}>
                  <li className="menu-title">{x.label}</li>
                  {x.items.map((y) => renderGroupItem(y))}
                </div>
              );
            })}
          </ul>
        </div>
        <div className="position-absolute bottom-1rem start-50 translate-middle-x">
          <small>
            Per assistenza scrivere a:{" "}
            <a href="mailto:tech@getholofinance.com">tech@getholofinance.com</a>
          </small>
        </div>
      </SimpleBar>
    </div>
  );
}

export const CompanyUtils = {
    getHoloIndex: function(company) {
        let last3Balances = this.getLast3Balances(company);
        let lastBalance = last3Balances.length && last3Balances[0];

        if (!lastBalance) return;
        if (!lastBalance.fatturato) return;

        let fundingYear = new Date(company.dataIscrizione).getFullYear();
        let shareCapital = lastBalance.capitaleSociale;
        let employees = lastBalance.dipendenti;
        let margins = lastBalance.utile;
        let turnover = lastBalance.fatturato;

        const last3Margins = last3Balances.map((x) => x.utile).reverse();
        const last3Turnover = last3Balances.map((x) => x.fatturato).reverse();

        const index = this.calculateHoloIndex(
            fundingYear,
            shareCapital,
            employees,
            margins,
            turnover,
            last3Margins,
            last3Turnover
        );

        const rating =
            (index.seniorityIndex +
                index.capitalIndex +
                index.dimensionIndex +
                index.marginIndex +
                index.growIndex) /
            5;

        return {
            indexValue: index,
            starsValue: rating,
        };
    },

    getLastBalanceYear: function(company) {
        if (!company || !company.dettaglioObj || !company.dettaglioObj.bilanci)
            return null;

        const attrKey = "bilancio";
        const currYear = new Date().getFullYear();

        let balances = company.dettaglioObj.bilanci;

        let balance = balances[`${attrKey}${currYear}`];
        let balancePrev = balances[`${attrKey}${currYear - 1}`];

        if (balance && balance.dataChiusuraBilancio) return currYear;
        if (balancePrev && balancePrev.dataChiusuraBilancio) return currYear - 1;
        return currYear - 2; //serve perché a cavallo del nuovo anno i due sopra potrebbero avere info ancora mancanti
    },

    getLast3Balances: function(company) {
        const lastBalanceYear = this.getLastBalanceYear(company);
        if (!lastBalanceYear) return;

        const attrKey = "bilancio";

        let balances = company.dettaglioObj.bilanci;

        let balance = balances[`${attrKey}${lastBalanceYear}`];
        let balancePrev = balances[`${attrKey}${lastBalanceYear - 1}`];
        let balancePrev2 = balances[`${attrKey}${lastBalanceYear - 2}`];

        return [balance, balancePrev, balancePrev2].filter((x) => x);
    },

    calculateHoloIndex: function(
        foundingYear,
        shareCapital,
        employees,
        margins,
        turnover,
        last3Margins,
        last3Turnover
    ) {
        //ANZIANITA - START
        const currentYear = new Date().getFullYear();
        let cYears = currentYear - foundingYear;

        //uso logaritmo in base 3 per una scala da 1 a 5
        let seniorityIndex = Math.round(Math.log(cYears) / Math.log(3));

        seniorityIndex = seniorityIndex > 5 ? 5 : seniorityIndex;
        seniorityIndex = seniorityIndex < 0 ? 0 : seniorityIndex;
        //ANZIANITA - END

        //CAPITALIZZAZIONE - START
        //uso logaritmo in base 75 per una scala da 1 a 5
        //metto a 0 tutto ciò che non ha capitale o inferiore a 1.000€
        let capitalIndex = 0;
        if (shareCapital > 1000)
            capitalIndex = Math.round(Math.log(shareCapital - 1000) / Math.log(75));
        else capitalIndex = 0;

        capitalIndex = capitalIndex > 5 ? 5 : capitalIndex;
        capitalIndex = capitalIndex < 0 ? 0 : capitalIndex;
        //CAPITALIZZAZIONE - END

        //DIMENSIONI - START
        //uso logaritmo in base 3 per gli addetti e medio con indice di capitale
        let employeesIndex = Math.round(Math.log(employees) / Math.log(4));
        let dimensionIndex = Math.round((employeesIndex + capitalIndex) / 2);

        dimensionIndex = dimensionIndex > 5 ? 5 : dimensionIndex;
        dimensionIndex = dimensionIndex < 0 ? 0 : dimensionIndex;
        //DIMENSIONI - END

        //MARGINE - START
        //uso logaritmo in base 2 per la percentuale di margine, se negativo indice margine 0
        let marginIndex = 0;
        if (margins > 0 && turnover > 0)
            marginIndex = Math.round(
                Math.log((margins / turnover) * 100) / Math.log(2)
            );

        marginIndex = marginIndex > 5 ? 5 : marginIndex;
        marginIndex = marginIndex < 0 ? 0 : marginIndex;
        //MARGINE - END

        //TREND - START
        let capitalDeltaTurnover2 =
            ((last3Turnover[2] - last3Turnover[1]) / last3Turnover[1]) * 100;
        let capitalDeltaTurnover1 =
            ((last3Turnover[1] - last3Turnover[0]) / last3Turnover[0]) * 100;
        let capitalDeltaMargin2 =
            ((last3Margins[2] - last3Margins[1]) / last3Margins[1]) * 100;
        let capitalDeltaMargin1 =
            ((last3Margins[1] - last3Margins[0]) / last3Margins[0]) * 100;

        //media crescita
        let avgDelta =
            (capitalDeltaMargin1 +
                capitalDeltaMargin2 +
                capitalDeltaTurnover1 +
                capitalDeltaTurnover2) /
            4;

        avgDelta = avgDelta < 0 ? 0 : avgDelta;

        //uso logaritmo in base 2.5 per una scala da 1 a 5
        let growIndex = Math.round(Math.log(avgDelta) / Math.log(2.5));

        growIndex = growIndex > 5 ? 5 : growIndex;
        growIndex = growIndex < 0 ? 0 : growIndex;
        //TREND - END

        return {
            seniorityIndex,
            capitalIndex,
            dimensionIndex,
            marginIndex,
            growIndex,
        };
    },
};
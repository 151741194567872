import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  Navigate,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { BusinessContext, RoleContext } from "./contexts/AppContexts";

import { BusinessesApi_Cache } from "./api/decorators/cache/BusinessesApi_Cache";
import { BusinessesRolesApi_Cache } from "./api/decorators/cache/BusinessesRolesApi_Cache";

//alphabetically
import Alerts from "./routes/Alerts";
import BankAccounts from "./routes/BankAccounts";
import Businesses from "./routes/Businesses";
import BusinessProfile from "./routes/BusinessProfile";
import Contacts from "./routes/Contacts";
import EstimatePreview from "./routes/EstimatePreview";
import Estimates from "./routes/Estimates";
import EstimateDetails from "./routes/EstimateDetails";
import Invoices from "./routes/Invoices";
import InvoiceDetails from "./routes/InvoiceDetails";
import InvoicePreview from "./routes/InvoicePreview";
import Orders from "./routes/Orders";
import OrderDetails from "./routes/OrderDetails";
import Payments from "./routes/Payments";
import PaymentDetails from "./routes/PaymentDetails";
import Plans from "./routes/Plans";
import PreInvoices from "./routes/PreInvoices";
import PreInvoiceDetails from "./routes/PreInvoiceDetails";
import PreInvoicePreview from "./routes/PreInvoicePreview";
import Profile from "./routes/Profile";
import Services from "./routes/Services";
import Settings from "./routes/Settings";
import Subscriptions from "./routes/Subscriptions";
import SubscriptionDetails from "./routes/SubscriptionDetails";
import Stripe from "./routes/Stripe";
import Supplier from "./routes/Supplier";

import Layout from "./routes/Layout";
import Dashboard from "./routes/Dashboard";
import RouteChangeHandler from "./routes/Handlers/RouteChangeHandler";
import Register from "./components/Register";

import Contact from "./routes/Contact";
import ContactProfile from "./routes/ContactProfile";
import Estimate from "./routes/Estimate";
import Invoice from "./routes/Invoice";
import Order from "./routes/Order";
import Service from "./routes/Service";
import Plan from "./routes/Plan";
import BankAccount from "./routes/BankAccount";

const HoloApp = () => {
  const [business, setBusiness] = useState(null);
  const [role, setRole] = useState(null);

  const [businesses, setBusinesses] = useState([]);
  const [roles, setRoles] = useState([]);

  // first load
  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (!business) return;

    // 0. Active
    if (business.status !== 0) {
      redirectToRegisterPage();
    }
  }, [business]);

  const redirectToRegisterPage = () => {
    if (!window.location.href.endsWith("/register")) {
      window.location.href = "/register";
    }
  };

  const load = async () => {
    const tBusinesses = await BusinessesApi_Cache.get(false, true);
    let tRoles = await BusinessesRolesApi_Cache.get(false, true);

    // if someone does not have any business, redirects to the register page
    if (!tBusinesses.length && !tRoles.length) {
      redirectToRegisterPage();
      return;
    }

    // after registration roles could be cached
    if (!tRoles.length) {
      tRoles = await BusinessesRolesApi_Cache.get(true);
    }

    setBusinesses(tBusinesses);
    setRoles(tRoles);

    if (!tBusinesses.length) return;
    if (!tRoles.length) return;
    if (business) return;

    const defaultRole = tRoles.find((x) => x.type === "owner");
    const defaultBusiness = tBusinesses.find(
      (x) => x.rowKey === defaultRole.partitionKey
    );

    setBusiness(defaultBusiness);
    setRole(defaultRole);
  };

  const handleImpersonate = (id) => {
    if (!businesses.length) return;
    if (!roles.length) return;

    if (!id) {
      const defaultRole = roles.find((x) => x.type === "owner");
      const defaultBusiness = businesses.find(
        (x) => x.rowKey === defaultRole.partitionKey
      );
      setBusiness(defaultBusiness);
      setRole(defaultRole);
      return;
    }

    const tBusiness = businesses.find((x) => x.rowKey === id);
    const tRole = roles.find((x) => x.partitionKey === tBusiness.rowKey);

    setBusiness(tBusiness);
    setRole(tRole);
  };

  return (
    <>
      <BusinessContext.Provider value={{ business, setBusiness }}>
        <Router>
          <Routes>
            <Route path="/register" element={<Register />} />
            <Route
              path="/"
              element={
                <RoleContext.Provider value={{ role, setRole }}>
                  <RouteChangeHandler>
                    {business?.status === 0 && (
                      <Layout onImpersonate={handleImpersonate} />
                    )}
                  </RouteChangeHandler>
                </RoleContext.Provider>
              }
            >
              <Route path="/" element={<Dashboard />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/bankAccounts" element={<BankAccounts />} />
              <Route path="/bankAccounts/:id?" element={<BankAccount />} />
              <Route
                path="/businesses"
                element={<Businesses onImpersonate={handleImpersonate} />}
              />
              <Route
                path="/business/:id?/profile"
                element={<BusinessProfile />}
              />
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/contact/:id?" element={<Contact />} />
              <Route
                path="/contact/:id?/profile"
                element={<ContactProfile />}
              />
              <Route path="/estimates" element={<Estimates />} />
              <Route path="/estimate/:id?" element={<Estimate />} />
              <Route
                path="/estimate/:id?/details"
                element={<EstimateDetails />}
              />
              <Route
                path="/estimate/:id/preview"
                element={<EstimatePreview />}
              />
              <Route path="/invoices" element={<Invoices />} />
              <Route path="/invoice/:id?" element={<Invoice />} />
              <Route
                path="/invoice/:id/details?"
                element={<InvoiceDetails />}
              />
              <Route path="/invoice/:id/preview" element={<InvoicePreview />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/order/:id?" element={<Order />} />
              <Route path="/order/:id?/details" element={<OrderDetails />} />
              <Route path="/services" element={<Services />} />
              <Route path="/service/:id?" element={<Service />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route
                path="/subscription/:id?/details"
                element={<SubscriptionDetails />}
              />
              <Route path="/payments" element={<Payments />} />
              <Route
                path="/payment/:id?/details"
                element={<PaymentDetails />}
              />
              <Route path="/plans" element={<Plans />} />
              <Route path="/plan/:id?" element={<Plan />} />
              <Route path="/preinvoices" element={<PreInvoices />} />
              <Route
                path="/preinvoice/:id/details?"
                element={<PreInvoiceDetails />}
              />
              <Route
                path="/preinvoice/:id/preview"
                element={<PreInvoicePreview />}
              />
              <Route path="/profile" element={<Profile />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/stripe" element={<Stripe />} />
              <Route path="/supplier/:id?" element={<Supplier />} />
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Router>
      </BusinessContext.Provider>
    </>
  );
};

export default HoloApp;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";

import { _BaseUtil } from "../utils/_BaseUtil";
import { ContactsUtil } from "../utils/ContactsUtil";
import { CompanyUtils } from "../utils/CompanyUtils";

import { HoloCompanyIndex } from "./HoloCompanyIndex";

import punycode from "punycode/";

export const ContactProfileDetails = ({ contact, company }) => {
  const { t } = useTranslation("general");

  const [holoIndex, setHoloIndex] = useState(null);
  const [holoRating, setHoloRating] = useState(null);

  useEffect(() => {
    if (!company) return;
    const holoIndex = CompanyUtils.getHoloIndex(company);
    if (holoIndex) {
      setHoloIndex(holoIndex.indexValue);
      setHoloRating(holoIndex.starsValue);
    }
  }, [company]);

  return (
    <div className="card overflow-hidden">
      <div className="bg-primary bg-soft">
        <div className="dropdown float-end m-2">
          <Link
            className="dropdown-toggle arrow-none"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link to={`/contact/${contact.rowKey}`} className="dropdown-item">
              Modifica
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <div className="text-primary p-3 mb-4">
              <h2 className="text-primary">
                {ContactsUtil.getFullName(contact)}
              </h2>
              <h6>
                Tipologia: {ContactsUtil.getTypeDescription(contact.type)}
              </h6>
              {contact.vatNumber && (
                <>
                  <h6>
                    P.IVA: {contact.vatNumber}
                    <i
                      onClick={() =>
                        _BaseUtil.copyToClipboard(contact.vatNumber, () => {
                          toast.success(t("copied-clipboard"), {
                            autoClose: 2500,
                          });
                        })
                      }
                      role="button"
                      className="fa-solid fa-copy ms-3 text-primary"
                    ></i>
                  </h6>
                </>
              )}
              {contact.phone && (
                <>
                  <h6>
                    Telefono: {contact.phone}
                    <i
                      onClick={() =>
                        _BaseUtil.copyToClipboard(contact.phone, () => {
                          toast.success(t("copied-clipboard"), {
                            autoClose: 2500,
                          });
                        })
                      }
                      role="button"
                      className="fa-solid fa-copy ms-3 text-primary"
                    ></i>
                  </h6>
                </>
              )}
              {contact.email && (
                <>
                  <h6>
                    Email: {punycode.toUnicode(contact.email)}
                    <i
                      onClick={() =>
                        _BaseUtil.copyToClipboard(
                          punycode.toUnicode(contact.email),
                          () => {
                            toast.success(t("copied-clipboard"), {
                              autoClose: 2500,
                            });
                          }
                        )
                      }
                      role="button"
                      className="fa-solid fa-copy ms-3 text-primary"
                    ></i>
                  </h6>
                </>
              )}
              {contact.certifiedEmail && (
                <>
                  <h6>
                    PEC: {punycode.toUnicode(contact.certifiedEmail)}
                    <i
                      onClick={() =>
                        _BaseUtil.copyToClipboard(
                          punycode.toUnicode(contact.certifiedEmail),
                          () => {
                            toast.success(t("copied-clipboard"), {
                              autoClose: 2500,
                            });
                          }
                        )
                      }
                      role="button"
                      className="fa-solid fa-copy ms-3 text-primary"
                    ></i>
                  </h6>
                </>
              )}
            </div>
          </div>
          <div className="col-5 align-self-end mt-3">
            <img
              src="/assets/images/profile-img-company.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>
      </div>
      <div className="card-body py-3">
        <div className="row">
          <div className="col-sm-4">
            <div className="avatar-md profile-user-wid mb-2">
              <img
                src={
                  contact.imagePath || "/assets/images/companies/hexagon.gif"
                }
                alt="logo azienda"
                className="img-thumbnail rounded-circle"
              />
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-nowrap mb-0">
              <tbody>
                {contact.firstName && (
                  <>
                    <tr>
                      <th scope="row">Contatto principale:</th>
                      <td>{`${contact.firstName} ${contact.lastName}`}</td>
                    </tr>
                  </>
                )}
                {contact.city && (
                  <>
                    <tr>
                      <th scope="row">Indirizzo:</th>
                      <td>
                        <a
                          href={`https://www.google.it/maps/search/${_BaseUtil.getFullAddress(
                            contact.address,
                            contact.streetNumber,
                            contact.zipCode,
                            contact.city,
                            contact.country
                          )}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span>
                            {_BaseUtil.getFullAddress(
                              contact.address,
                              contact.streetNumber,
                              contact.zipCode,
                              contact.city,
                              contact.country
                            )}
                          </span>
                          <i className="bx bx-link-external ms-2"></i>
                        </a>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {holoIndex && holoRating && (
        <div className="row mt-5">
          <div className="col-12 d-flex flex-column text-center">
            <div className="fw-bold">
              Holo Rating:
              <ReactStars
                count={5}
                value={holoRating}
                isHalf={true}
                size={24}
                edit={false}
                activeColor="#ffd700"
                classNames={`ms-auto me-auto`}
              ></ReactStars>
              <span className="badge bg-info">{holoRating}</span>
            </div>
          </div>
          <div className="col-12 d-flex flex-column text-center">
            <HoloCompanyIndex
              seniorityIndex={holoIndex.seniorityIndex}
              capitalIndex={holoIndex.capitalIndex}
              dimensionIndex={holoIndex.dimensionIndex}
              marginIndex={holoIndex.marginIndex}
              growIndex={holoIndex.growIndex}
              company={company}
            />
          </div>
        </div>
      )}
    </div>
  );
};

import React, { useContext } from "react";
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select from "react-select";

import { BusinessContext } from "../contexts/AppContexts";

import { BusinessesApi_Cache } from "../api/decorators/cache/BusinessesApi_Cache";

import SelectProvince from "../components/Select/SelectProvince";

import atecoCodes from "../assets/atecoCodes.json"; // Aggiungi il percorso corretto al tuo file JSON

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { _BaseUtil } from "../utils/_BaseUtil";

export const CardBusinessSettings = () => {
  const { business, setBusiness } = useContext(BusinessContext);

  const mapAtecoCodeOption = (atecoCode) => {
    if (!atecoCode) return null;
    return {
      value: atecoCode.value,
      label: `${atecoCode.value} - ${atecoCode.label}`,
    };
  };

  let formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      ...business,
      atecoCodes:
        business.atecoCodes
          ?.split("|")
          ?.map((x) =>
            mapAtecoCodeOption(atecoCodes.find((y) => x === y.value))
          ) || [],
    },
    validationSchema: Yup.object({
      businessName: Yup.string().required("Ragione sociale obbligatoria"),
      address: Yup.string().required("Indirizzo obbligatorio"),
      zipCode: Yup.string().required("CAP obbligatorio"),
      city: Yup.string().required("Città obbligatoria"),
      province: Yup.string()
        .required("Provincia obbligatoria")
        .matches(
          /^[a-zA-Z]{2}$/,
          "La provincia deve essere composta da esattamente due lettere"
        ),
      vatNumber: Yup.string()
        .required("Partita IVA obbligatoria")
        .matches(_BaseUtil.regex_PIVA),
      certifiedEmail: Yup.string().required("PEC obbligatoria"),
      taxRegime: Yup.string().required("Regime fiscale obbligatorio"),
      atecoCodes: Yup.array()
        .min(1, "Seleziona almeno un Codice ATECO")
        .required("Il campo Codice ATECO è obbligatorio"),
      sequenceLetter: Yup.string()
        .matches(
          /^[a-zA-Z]*$/, // Usa * per accettare anche stringhe vuote
          "Il campo deve contenere solo lettere"
        )
        .notRequired(),
    }),
    onSubmit: (values) => {
      console.log(`values`, values);
      handleSubmit();
    },
  });

  const handleSubmit = async () => {
    try {
      const errors = await formik.validateForm();

      if (Object.keys(errors).length) {
        // Perform your desired action for invalid form
        console.log("Form is not invalid", errors);
        return;
      }

      const atecoSelected = formik.values.atecoCodes.map((option) => option);

      let obj = {
        ...formik.values,
        atecoCodes: atecoSelected.map((x) => x.value).join("|"),
        atecoDescriptions: atecoSelected.map((x) => x.label).join("|"),
      };

      obj.eTag = undefined;
      obj.createdOn = undefined;

      // Perform your desired action for valid form
      console.log("Form is valid", obj);

      const result = await BusinessesApi_Cache.update(business.rowKey, obj);
      if (result) setBusiness(result);
    } catch (error) {
      console.error("Form validation error:", error);
    }
  };

  return (
    <>
      <div className="card">
        <h5 className="card-header bg-transparent border-bottom text-uppercase">
          La tua attività
        </h5>
        <div className="card-body">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            <Row>
              <Col>
                <FormGroup>
                  <label htmlFor="businessName" className="form-label">
                    Ragione sociale
                  </label>
                  <Input
                    type="text"
                    id="businessName"
                    name="businessName"
                    className="form-control"
                    placeholder="Inserisci la ragione sociale"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.businessName || ""}
                    invalid={
                      formik.touched.businessName && formik.errors.businessName
                    }
                  />
                  {formik.touched.businessName &&
                    formik.errors.businessName && (
                      <FormFeedback type="invalid">
                        {formik.errors.businessName}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="vatNumber" className="form-label">
                    Partita IVA
                  </label>
                  <Input
                    type="text"
                    id="vatNumber"
                    name="vatNumber"
                    className="form-control"
                    placeholder="Inserisci la tua partita iva"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vatNumber || ""}
                    invalid={
                      formik.touched.vatNumber && formik.errors.vatNumber
                    }
                  />
                  {formik.touched.vatNumber && formik.errors.vatNumber && (
                    <FormFeedback type="invalid">
                      {formik.errors.vatNumber}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="eInvoiceProviderCode" className="form-label">
                    Codice Destinatario
                  </label>
                  <Input
                    type="text"
                    id="eInvoiceProviderCode"
                    name="eInvoiceProviderCode"
                    className="form-control"
                    placeholder="Inserisci il tuo Codice Destinatario"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.eInvoiceProviderCode || ""}
                    invalid={
                      formik.touched.eInvoiceProviderCode &&
                      formik.errors.eInvoiceProviderCode
                    }
                  />
                  {formik.touched.eInvoiceProviderCode &&
                    formik.errors.eInvoiceProviderCode && (
                      <FormFeedback type="invalid">
                        {formik.errors.eInvoiceProviderCode}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="certifiedEmail" className="form-label">
                    PEC
                  </label>
                  <Input
                    type="text"
                    id="certifiedEmail"
                    name="certifiedEmail"
                    className="form-control"
                    placeholder="Indirizzo PEC"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.certifiedEmail || ""}
                    invalid={
                      formik.touched.certifiedEmail &&
                      formik.errors.certifiedEmail
                    }
                  />
                  {formik.touched.certifiedEmail &&
                    formik.errors.certifiedEmail && (
                      <FormFeedback type="invalid">
                        {formik.errors.certifiedEmail}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="address" className="form-label">
                    Indirizzo
                  </label>
                  <Input
                    type="text"
                    id="address"
                    name="address"
                    className="form-control"
                    placeholder="Inserisci il tuo indirizzo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address || ""}
                    invalid={formik.touched.address && formik.errors.address}
                  />
                  {formik.touched.address && formik.errors.address && (
                    <FormFeedback type="invalid">
                      {formik.errors.address}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="streetNumber" className="form-label">
                    Civico
                  </label>
                  <Input
                    type="text"
                    id="streetNumber"
                    name="streetNumber"
                    className="form-control"
                    placeholder="Numero civico"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.streetNumber || ""}
                    invalid={
                      formik.touched.streetNumber && formik.errors.streetNumber
                    }
                  />
                  {formik.touched.streetNumber &&
                    formik.errors.streetNumber && (
                      <FormFeedback type="invalid">
                        {formik.errors.streetNumber}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="zipCode" className="form-label">
                    Cap
                  </label>
                  <Input
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    className="form-control"
                    placeholder="Inserisci il CAP"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipCode || ""}
                    invalid={formik.touched.zipCode && formik.errors.zipCode}
                  />
                  {formik.touched.zipCode && formik.errors.zipCode && (
                    <FormFeedback type="invalid">
                      {formik.errors.zipCode}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="city" className="form-label">
                    Città
                  </label>
                  <Input
                    type="text"
                    id="city"
                    name="city"
                    className="form-control"
                    placeholder="Inserisci la città"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city || ""}
                    invalid={formik.touched.city && formik.errors.city}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <FormFeedback type="invalid">
                      {formik.errors.city}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6} md={4}>
                <FormGroup>
                  <label htmlFor="province" className="form-label">
                    Provincia
                  </label>
                  <SelectProvince
                    id="province"
                    name="province"
                    value={formik.values.province || ""}
                    onChange={(value) =>
                      formik.setFieldValue("province", value)
                    }
                    onBlur={() => formik.setFieldTouched("province", true)}
                    invalid={formik.touched.province && formik.errors.province}
                  />
                  {formik.touched.province && formik.errors.province && (
                    <div style={{ color: "red", marginTop: ".5rem" }}>
                      {formik.errors.province}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm={6} md={4}>
                <FormGroup>
                  <Label htmlFor="countryCode" className="form-label">
                    Stato
                  </Label>
                  <select
                    id="countryCode"
                    name="countryCode"
                    className="form-control form-select"
                    value={formik.values.countryCode || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    invalid={
                      formik.touched.countryCode && formik.errors.countryCode
                    }
                  >
                    <option value="IT">Italia</option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label htmlFor="businessEmail" className="form-label">
                    Email
                  </label>
                  <Input
                    type="email"
                    id="businessEmail"
                    name="businessEmail"
                    className="form-control"
                    placeholder="Inserisci il tuo indirizzo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.businessEmail || ""}
                    invalid={
                      formik.touched.businessEmail &&
                      formik.errors.businessEmail
                    }
                  />
                  {formik.touched.businessEmail &&
                    formik.errors.businessEmail && (
                      <FormFeedback type="invalid">
                        {formik.errors.businessEmail}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label htmlFor="taxRegime" className="form-label">
                    Regime Fiscale
                  </label>
                  <Input
                    type="text"
                    id="taxRegime"
                    name="taxRegime"
                    className="form-control"
                    placeholder="Regime fiscale. Esempio: RF01"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taxRegime || ""}
                    invalid={
                      formik.touched.taxRegime && formik.errors.taxRegime
                    }
                  />
                  {formik.touched.taxRegime && formik.errors.taxRegime && (
                    <FormFeedback type="invalid">
                      {formik.errors.taxRegime}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label htmlFor="atecoCodes">Codici Ateco</Label>
                  <Select
                    id="atecoCodes"
                    name="atecoCodes"
                    options={atecoCodes.map((x) => mapAtecoCodeOption(x))} // Usa direttamente il tuo JSON importato
                    isMulti
                    onChange={(option) =>
                      formik.setFieldValue("atecoCodes", option)
                    }
                    onBlur={() => formik.setFieldTouched("atecoCodes", true)}
                    value={formik.values.atecoCodes}
                  />
                  {formik.touched.atecoCodes && formik.errors.atecoCodes && (
                    <div style={{ color: "red", marginTop: ".5rem" }}>
                      {formik.errors.atecoCodes}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label htmlFor="sequenceLetter" className="form-label">
                    Sequenziale
                  </label>
                  <Input
                    type="text"
                    id="sequenceLetter"
                    name="sequenceLetter"
                    className="form-control"
                    placeholder="Sequenziale. Esempio: H"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sequenceLetter || ""}
                    invalid={
                      formik.touched.sequenceLetter &&
                      formik.errors.sequenceLetter
                    }
                  />
                  {formik.touched.sequenceLetter &&
                    formik.errors.sequenceLetter && (
                      <FormFeedback type="invalid">
                        {formik.errors.sequenceLetter}
                      </FormFeedback>
                    )}
                </FormGroup>
              </Col>
            </Row>
            <div className="text-sm-end">
              <button type="submit" className="btn btn-primary w-md">
                Salva
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
